import React from 'react';
import {FontText} from "../../../Components/titles";

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

export default ({data, select})=>(

    <div>
        <Card style={{ width: '20rem', marginBottom: 20 }}>
            <Card.Img variant="top" style={{height:150, objectFit: 'cover'}} src={data.image_url?data.image_url:"https://myautoshop.co.nz/cache/images/3/c/6/5/5/3c655d6ee44e558515fc6d17b06a4258e1eb90b0.png"} />
            <Card.Body>

                <div style={{display:'flex', flexDirection:'column', minHeight:130}}>
                    <b>{data.name}</b>



                    <div style={{display:'flex', justifyContent: 'space-between'}}>
                        <b style={{fontSize:14}}>{data.rating} Stars</b>

                        <b style={{fontSize:14}}>{data.distance}km away</b>
                    </div>

                    <span style={{fontSize:14}}>{data.address}</span>



                </div>

                <Button style={{alignSelf: 'flex-end'}} block variant="warning" onClick={()=>select(data.cms_id)}>Select</Button>


            </Card.Body>
        </Card>
    </div>

)
