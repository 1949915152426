import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import TyresScreen from '../Screens/retail/tyres';
import WindscreensScreen from '../Screens/retail/windscreens';
import GarageScreen from '../Screens/retail/garages';
import RequestScreen from '../Screens/quote/request';
import ErrorScreen from '../Screens/error';
import NotFoundScreen from '../Screens/notfound';
import SuccessScreen from '../Screens/success';


export default () => (

    <div className={'header-spacing'} style={{
        // marginTop:120,
        marginBottom:50
    }}>
        <Router>
            <Switch>
                <Route exact path="/" component={() => {
                    window.location.href = 'https://myautoshop.co.nz';
                    return null;
                }} >
                </Route>
                <Route exact path="/tyres/:identifier">
                    <TyresScreen />
                </Route>
                <Route exact path="/windscreens/:identifier">
                    <WindscreensScreen />
                </Route>
                <Route exact path="/garages/:identifier">
                    <GarageScreen />
                </Route>
                <Route exact path="/request/:identifier">
                    <RequestScreen />
                </Route>
                <Route exact path="/error/session">
                    <ErrorScreen />
                </Route>
                <Route exact path="/success">
                    <SuccessScreen />
                </Route>

                <Route component={NotFoundScreen} />
            </Switch>
        </Router>
    </div>

)
