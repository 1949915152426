import React, {useState} from 'react';
import Router from "./Router";

export default () => {

    const [open, setOpen] = useState(false);


    return (

    <header data-v-7cbe19f6="" id="site-header" className={`site-header  ${open?'open':''}`}>
        <div data-v-7cbe19f6="" className="wrap">
            <a data-v-7cbe19f6="" href="https://myautoshop.co.nz/" className="logo">
                <img data-v-7cbe19f6="" src={require('../assets/images/logo.png')} alt="My Auto Shop Logo"/>
            </a>
            <nav data-v-7cbe19f6="">
                <ul data-v-7cbe19f6="" className="top">
                    <li data-v-7cbe19f6="">
                        <a className="nav-button" data-v-7cbe19f6="" href="https://myautoshop.co.nz">Home</a>
                    </li>
                    <li data-v-7cbe19f6="">
                        <a className="nav-button" data-v-7cbe19f6="" href="https://myautoshop.co.nz/find-a-mechanic-near-you/garages-index">Garages</a>
                    </li>
                    <li data-v-7cbe19f6="">
                        <a className="nav-button" data-v-7cbe19f6="" href="https://myautoshop.co.nz/services-index">Services</a>
                    </li>
                    <li data-v-7cbe19f6=""><a className="nav-button" data-v-7cbe19f6="" href="https://myautoshop.co.nz/faq">FAQs</a></li>
                    <li data-v-7cbe19f6=""><a className="nav-button" data-v-7cbe19f6="" href="https://myautoshop.co.nz/contact">Contact</a>
                    </li>
                    <li data-v-7cbe19f6=""><a data-v-7cbe19f6="" href="tel:09 887 8317"><strong data-v-7cbe19f6="">(09)
                        887 8317</strong></a></li>
                    <li data-v-7cbe19f6="" className="button-li"><a data-v-7cbe19f6="" href="https://myautoshop.co.nz/join-us-as-a-workshop"
                                              className="button">Join as a Garage</a></li>
                    <li data-v-7cbe19f6="" className="button-li"><a data-v-7cbe19f6="" href="https://myautoshop.co.nz/drive-with-my-auto-shop"
                                              className="button">Become a Driver</a></li>
                </ul>
            </nav>
            <button data-v-7cbe19f6="" className="hamburger mobile" onClick={()=>{
                setOpen(!open);
            }}>
                <span data-v-7cbe19f6=""></span>
                <span data-v-7cbe19f6=""></span>
                <span data-v-7cbe19f6=""></span>
            </button>
        </div>
    </header>
    )

}
