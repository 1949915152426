import React from 'react';
import logo from './logo.svg';
import './App.css';
import Router from './Navigation/Router'
import Header from './Navigation/Header'
import Footer from './Navigation/Footer'

import Container from 'react-bootstrap/Container'



function App() {
  return (
      <div>
          <Header/>
          <Container>

              <Router/>
          </Container>
          <hr style={{marginTop:0, marginBottom:0}}/>
          <Container>
              <Footer/>


          </Container>

      </div>

  );
}

export default App;
