import React, {useState, useEffect} from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {ButtonText, FontText, Heading, HeadingText} from '../../Components/titles';
import {BoolButtons} from '../../Components/form';

import Garage from './components/garage'

import {useParams} from "react-router-dom";

import Spinner from '../../Components/spinner'



export default ({onGarageSelect, type}) => {

    const params = useParams();

    const [distance, setDistance] = useState(10);
    const [garages, setGarages] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [step, setStep] = useState(0);

    const [firstname, setFirstname] =  useState('');
    const [lastname, setLastname] =  useState('');
    const [kms, setKms] =  useState('');
    const [registration, setRegistration] =  useState('');
    const [email, setEmail] =  useState('');
    const [phone, setPhone] =  useState('');
    const [isAgreed, setAgreed] = useState(false);


    useEffect(()=>{

        fetch(`/book/request/${params.identifier}/garages?distance=${distance}&type=${type}`)
            .then(res => res.json())
            .then(
                (garages_json) => {
                    setGarages(garages_json);

                    setLoading(false);
                })

    }, [ params.identifier]);

    if (isLoading){
        return <Spinner animation="border" variant="warning" />
    }

    return (


        <div>
            {step === 0?

                <div>

                    <HeadingText>
                        Add your contact details!
                    </HeadingText>
                    <Row className={'mb-sm-3 mt-3'}>
                        <Col md={6}  className={'pr-md-1'}>
                            <Form.Label style={styles.label}>First Name <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control type="text" name="firstname" placeholder="First Name"
                                          value={firstname} onChange={(event)=>{setFirstname(event.target.value)}} style={{height:56}} />

                        </Col>
                        <Col md={6} className={'pl-md-1'}>
                            <Form.Label style={styles.label}>Last Name <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control type="text" name="lastname" placeholder="Last Name"
                                          value={lastname} onChange={(event)=>{setLastname(event.target.value)}} style={{height:56}}/>

                        </Col>
                    </Row>


                    <Row className={'mb-sm-3'}>
                        <Col md={6}  className={'pr-md-1'}>
                            <Form.Label style={styles.label}>Email <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control type="text" name="email" placeholder="Email"
                                          value={email} onChange={(event)=>{setEmail(event.target.value)}} style={{height:56}}/>

                        </Col>
                        <Col md={6} className={'pl-md-1'}>
                            <Form.Label style={styles.label}>Phone <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control type="text" name="phone" placeholder="Phone"
                                          value={phone} onChange={(event)=>{setPhone(event.target.value)}} style={{height:56}}/>

                        </Col>
                    </Row>

                    <Row className={'mb-sm-3'}>

                        <Col md={6}  className={'pr-md-1'}>
                            <Form.Label style={styles.label}>Vehicle Registration <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control type="text" name="registration" placeholder="eg. ABC123"
                                          value={registration} onChange={(event)=>{setRegistration(event.target.value)}} style={{height:56}}/>

                        </Col>
                        <Col md={6}  className={'pl-md-1'}>
                            <Form.Label style={styles.label}>Odometer (optional)</Form.Label>
                            <Form.Control type="text" name="kms" placeholder="Approximate KMs Travelled"
                                          value={kms} onChange={(event)=>{setKms(event.target.value)}} style={{height:56}}/>

                        </Col>
                    </Row>
                    <Row className={'mb-sm-3'}>

                        <Col md={12}  className={'pr-md-1'}>
                            <Form.Check style={{paddingTop: 15}}>
                                <Form.Check.Input isValid checked={isAgreed} onClick={()=>setAgreed(!isAgreed)}/>
                                <Form.Check.Label style={{color: 'black', fontSize:16}}>
                                    I agree to the <a style={{textDecoration: 'underline', color: '#5b5b5b'}} href={"https://myautoshop.co.nz/terms-and-conditions"} target="_blank" >Terms and Conditions</a> <span style={{color: 'red'}}>*</span>
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>

                    <Button style={{height:70, marginTop:28, marginBottom:28, width: 400, maxWidth:'100%'}} variant="warning" onClick={()=>{setStep(1)}}
                            disabled={!(firstname && lastname && email && phone && registration && isAgreed)}


                    >
                        <FontText size={16} weight={800} color={'white'}>
                            Continue
                        </FontText>
                    </Button>

                </div>:

                <div>
                    <HeadingText>
                        Check out the <span style={{color:'#fecb0a'}}>{garages.length}</span> great garages in your neighbourhood below.
                    </HeadingText>

                    <div style={{display: 'flex', flexWrap:'wrap', justifyContent: "space-between", marginTop:30}}>

                        {
                            garages.map((garage)=> <Garage data={garage} select={(id)=>{onGarageSelect(id, {

                                firstname: firstname,
                                lastname: lastname,
                                email: email,
                                phone: phone,
                                kms: kms,
                                registration: registration,

                            })}}/>)
                        }
                    </div>
                </div>}
        </div>





    )

}

const styles = {
    label: {marginBottom: 10, fontSize: 14, fontWeight: 600, color: '#191919', marginTop:10},
    input: {marginBottom: 10, fontSize: 14, fontWeight: 600, color: '#191919'}
};
