import React from 'react';

import {Heading, ButtonText, FontText} from '../../../Components/titles';

import Button from 'react-bootstrap/Button'
import Form from "react-bootstrap/Form";


export default ({title, image, brand, isSelected, price, onSelect, size,priceColor})=> {

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderStyle: 'solid',
            borderWidth: 3,
            borderRadius: 5,
            padding: 5,
            borderColor: isSelected?'#fecb0a':'#ffffff',
        }}>

            <div style={{backgroundColor:priceColor, width:'100%', borderRadius:3, display:'flex', flexDirection:'column', alignItems:'center'}}>
                <FontText size={32}>{price}</FontText>
                <FontText size={14} style={{marginTop:-10}}>each</FontText>
            </div>


            <img src={brand} style={{
                width: 200,
                height:50,
                objectFit: 'contain',
                marginTop:20,
                marginBottom:20,
            }} />
            <img src={image} style={{
                width: 200,
                height: 300
            }} />


            <FontText weight={200}>{size['width']&&size['profile']&&size['rim']?`${size['width']}/${size['profile']}R${size['rim']}`:''}</FontText>

            {/*<div style={{display:"flex", flexDirection: 'row'}}>*/}

            {/*    <div style={{display:"flex", alignItems:'center', flexDirection: 'column', margin:5}}>*/}
            {/*        <div style={{backgroundColor: '#fecb0a', color: '#ffffff', padding: 5, borderRadius: 15,  height: 30, width:30, display:'flex', justifyContent: 'center', alignItems:'center'}}>*/}
            {/*            95*/}
            {/*        </div>*/}
            {/*        <FontText size={12} weight={200}>LOAD</FontText>*/}
            {/*    </div>*/}
            {/*    <div style={{display:"flex", alignItems:'center', flexDirection: 'column', margin:5}}>*/}
            {/*        <div style={{backgroundColor: '#fecb0a', color: '#ffffff', padding: 5, borderRadius: 15,  height: 30, width:30, display:'flex', justifyContent: 'center', alignItems:'center'}}>*/}
            {/*            W*/}
            {/*        </div>*/}
            {/*        <FontText size={12} weight={200}>SPEED</FontText>*/}
            {/*    </div>*/}

            {/*</div>*/}

            <div style={{marginTop:10}}/>

            <Button block variant={isSelected?'warning':'outline-warning'} onClick={onSelect} disabled={isNaN(price.replace("$", ""))} style={{height:50}}>
                <ButtonText text={isNaN(price.replace("$", ""))?'Unavailable':"Select Me"}/>
            </Button>
        </div>

    )

}
