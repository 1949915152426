import React, {useState, useEffect} from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {ButtonText, FontText, Heading, HeadingText} from '../Components/titles';



export default () => {

    return (

        <div>

            <div className="image" style={{textAlign: 'center'}}><img
                src={require('../assets/images/basket/icon_7.png')}
                alt="Home" width={150} /></div>
            <FontText size={36} weight={800} style={{textAlign: 'center'}}>
                Success!
            </FontText>


            <FontText size={20} style={{textAlign: 'center'}}>
                Now let us handle the rest.
            </FontText>

            <section className="how-container" style={{paddingBottom:0}}>
                <div className="row">
                    <article className="col-md-4">
                        <div className="image"><img
                            src={require('../assets/images/basket/icon_1.png')}
                            alt="Home" /></div>
                        <div>
                            <FontText size={20}>1. We gather quotes from local garages.</FontText>
                        </div>
                    </article>
                    <article className="col-md-4">
                        <div className="image"><img
                            src={require('../assets/images/basket/icon_2.png')}
                            alt="Home" /></div>
                        <div>
                            <FontText size={20}>2. Then we email you the best ones within 24 hours.</FontText>
                        </div>
                    </article>
                    <article className="col-md-4">
                        <div className="image"><img
                            src={require('../assets/images/basket/icon_3.png')}
                            alt="Home" /></div>
                        <div>
                            <FontText size={20}>3. You'll be back on the road in no time.</FontText>
                        </div>
                    </article>
                </div>
            </section>

        </div>


    )

}
