import React from 'react';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {ButtonText} from "./titles";
import Row from "react-bootstrap/Row";



export const BoolButtons = ({onTrue, onFalse, value})=>(

    <Row>
        <Col xs={6} style={{paddingLeft:5, paddingRight:5}}>
            <Button variant={value?"warning":"outline-warning"} style={{width:'100%', height:50}} onClick={onTrue}>
                <ButtonText text={'Yes'}/>
            </Button>
        </Col>
        <Col xs={6} style={{paddingLeft:5, paddingRight:5}}>
            <Button variant={value===false?"warning":"outline-warning"} style={{width:'100%', height:50}} onClick={onFalse}>
                <ButtonText text={'No'}/>
            </Button>
        </Col>
    </Row>

);
