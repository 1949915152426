import React, {useState, useEffect} from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {ButtonText, FontText, Heading, HeadingText} from '../../Components/titles';
import {BoolButtons} from '../../Components/form';
import Tyre from './components/tyre'
import Price from './components/price'
import Basket from './components/basket'

import {useParams, useHistory} from "react-router-dom";
import {Retail as GetRequest} from "../../utils/GetRequest";
import Spinner from '../../Components/spinner'
import Garages from "./garages";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

export default () => {

    const params = useParams();


    const [step, setStep] = useState(0);

    const [camera, setCamera] = useState(null);
    const [fov, setFOV] = useState(null);
    const [type, setType] = useState(null);
    const [size, setSize] = useState(null);
    const [extras, setExtras] = useState(0);
    const [price, setPrice] = useState('TBD');
    const [itemTitle, setItemTitle] = useState("Windscreen Repair");

    const [onGaragePage, setOnGaragePage] = useState(false);


    const [job, setJob] = useState({});


    const getPrice = () => {
        if (size === null) return;

        setPrice('Loading');

        fetch('/book/windscreens', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({option: size, extras: extras})
        })
            .then((res => res.json()))
            .then(price_data => {
                setPrice(price_data.price?`$${parseFloat(price_data.price).toFixed(2)}`:'Quote Required');
            })
    };

    const reset = (step) => {

        if (step <= 0) setFOV(null);
        if (step <= 1) setType(null);
        if (step <= 2) setSize(null);
        if (step <= 3) setExtras(0);
        if (step <= 3) setPrice('TBD');
    };

    const typeMap = [

        "Crack",
        "Star",
        "Horseshoe",
        "Bullseye",
        "Crater",


    ];

    useEffect(()=>{
        getPrice();
    }, [size, extras]);

    useEffect(()=>{
            setItemTitle(`Windscreen Repair ${type!==null?"("+typeMap[type]+")":""} ${extras?" +" + extras + " extra chips":""}`)

        }, [type, extras]
    );

    const history = useHistory();
    const [isLoading, setLoading] = useState(true);

    useEffect(()=>{

        GetRequest(params.identifier, setJob, setLoading, history);

    }, [params.identifier]);


    if (isLoading)
        return   <Spinner animation="border" variant="warning" />

    else return (

        <div>

            {onGaragePage ?
                <Garages
                    type={'windscreens'}
                    onGarageSelect={(id) => {

                    setLoading(true);

                    fetch("/book/windscreens/" + params.identifier + '/book', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({

                            identifier: params.identifier,
                            garage: id,
                            option: size,
                            extras: extras

                        })
                    })
                        .then((res => res.json()))
                        .then(data => {

                            if (data.success) {
                                window.location = data.url;
                            } else {
                                history.push('/error/session')
                            }

                        })

                }}/>

                :

                <div>

                    <HeadingText>
                        Lets get your <span style={{color: '#fecb0a'}}>Windscreen Repair</span> sorted!
                    </HeadingText>

                    <div style={{marginTop: 20}}>
                        <FontText size={20} weight={200}>
                            With just a few questions, we can determine if your windscreen can be repaired or if you'll
                            need a new one.
                        </FontText>
                    </div>


                    <Row style={{marginTop: 30}}>
                        <Col md={8}>


                            {step >= 0 ?
                                <section style={{paddingBottom: 50}}>


                                    <Heading
                                        text={"1. Is the damage directly in front of a windscreen mounted camera or sensor?"}/>

                                    <div style={{paddingTop: 28}}/>

                                    <BoolButtons
                                        value={camera}
                                        onTrue={() => {
                                            reset(0);
                                            setCamera(true);
                                            setStep(0);
                                            setSize(10);
                                        }}
                                        onFalse={() => {
                                            reset(0);
                                            setCamera(false);
                                            setStep(1);
                                            setSize(null);
                                        }}
                                    />

                                </section>
                                : null}

                            {step >= 1 ?

                                <section style={{paddingBottom: 50}}>
                                    <Heading text={"2. Is the chip or crack directly in the drivers field of vision?"}/>

                                    <div style={{paddingTop: 28}}/>

                                    <BoolButtons
                                        value={fov}
                                        onTrue={() => {
                                            reset(1);
                                            setFOV(true);
                                            setStep(2);
                                        }}
                                        onFalse={() => {
                                            reset(1);
                                            setFOV(false);
                                            setStep(2);
                                        }}
                                    />

                                </section>
                                : null}

                            {step >= 2 ?

                                <section style={{paddingBottom: 50}}>
                                    <Heading text={"3. What does the crack look like?"}/>

                                    <div style={{paddingTop: 28}}/>

                                    <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                                        <Button variant={type === 0 ? "warning" : "outline-warning"}
                                                style={{width: 130, borderWidth: 0}} onClick={() => {
                                            reset(2);
                                            setType(0);
                                            setStep(3);
                                        }}>

                                            <img src={require('../../assets/images/windscreens/type-crack.png')}/>
                                        </Button>
                                        <Button variant={type === 1 ? "warning" : "outline-warning"}
                                                style={{width: 130, borderWidth: 0}} onClick={() => {
                                            reset(2);
                                            setType(1);
                                            setStep(3);
                                        }}>

                                            <img src={require('../../assets/images/windscreens/type-star.png')}/>
                                        </Button>
                                        <Button variant={type === 2 ? "warning" : "outline-warning"}
                                                style={{width: 130, borderWidth: 0}} onClick={() => {
                                            reset(2);
                                            setType(2);
                                            setStep(3);
                                        }}>

                                            <img src={require('../../assets/images/windscreens/type-horseshoe.png')}/>
                                        </Button>
                                        <Button variant={type === 3 ? "warning" : "outline-warning"}
                                                style={{width: 130, borderWidth: 0}} onClick={() => {
                                            reset(2);
                                            setType(3);
                                            setStep(3);
                                        }}>

                                            <img src={require('../../assets/images/windscreens/type-bullseye.png')}/>
                                        </Button>
                                        <Button variant={type === 4 ? "warning" : "outline-warning"}
                                                style={{width: 120, borderWidth: 0}} onClick={() => {
                                            reset(2);
                                            setType(4);
                                            setStep(3);
                                        }}>

                                            <img src={require('../../assets/images/windscreens/type-crater.png')}/>
                                        </Button>
                                    </div>

                                </section>

                                : null}


                            {step >= 3 ?

                                <section>
                                    <Heading text={`4. How big is the ${typeMap[type]}?`}/>
                                    <div style={{paddingTop: 28}}/>


                                    {camera === false && fov === false && (type <= 1 && type != null) ?

                                        <Row>
                                            <Col xs={6} style={{paddingLeft: 5, paddingRight: 5, height: 50}}>
                                                <Button variant={size === 0 ? "warning" : "outline-warning"}
                                                        style={{width: '100%'}} onClick={() => {
                                                    reset(3);
                                                    setSize(0);
                                                    setStep(4);
                                                }}>
                                                    <ButtonText text={'Less than 2.5cm Diameter'}/>
                                                </Button>
                                            </Col>
                                            <Col xs={6} style={{paddingLeft: 5, paddingRight: 5, height: 50}}>
                                                <Button variant={size === 1 ? "warning" : "outline-warning"}
                                                        style={{width: '100%'}} onClick={() => {
                                                    reset(3);
                                                    setSize(1);
                                                    setStep(4);
                                                }}>
                                                    <ButtonText text={'More than 2.5cm Diameter'}/>
                                                </Button>
                                            </Col>
                                        </Row>

                                        : null}

                                    {camera === false && fov === false && (type >= 2 && type != null) ?

                                        <Row>
                                            <Col xs={4} style={{paddingLeft: 5, paddingRight: 5, height: 50}}>
                                                <Button variant={size === 2 ? "warning" : "outline-warning"}
                                                        style={{width: '100%'}} onClick={() => {
                                                    reset(3);
                                                    setSize(2);
                                                    setStep(4);
                                                }}>
                                                    <ButtonText text={'Less than 10cm'}/>
                                                </Button>
                                            </Col>
                                            <Col xs={4} style={{paddingLeft: 5, paddingRight: 5, height: 50}}>
                                                <Button variant={size === 3 ? "warning" : "outline-warning"}
                                                        style={{width: '100%'}} onClick={() => {
                                                    reset(3);
                                                    setSize(3);
                                                    setStep(4);
                                                }}>
                                                    <ButtonText text={'Between 10cm and 35cm'}/>
                                                </Button>
                                            </Col>
                                            <Col xs={4} style={{paddingLeft: 5, paddingRight: 5, height: 50}}>
                                                <Button variant={size === 4 ? "warning" : "outline-warning"}
                                                        style={{width: '100%'}} onClick={() => {
                                                    reset(3);
                                                    setSize(4);
                                                    setStep(4);
                                                }}>
                                                    <ButtonText text={'More than 35cm'}/>
                                                </Button>
                                            </Col>
                                        </Row>

                                        : null}

                                    {camera === false && fov === true && (type <= 1 && type != null) ?

                                        <Row>
                                            <Col xs={6} style={{paddingLeft: 5, paddingRight: 5, height: 50}}>
                                                <Button variant={size === 5 ? "warning" : "outline-warning"}
                                                        style={{width: '100%'}} onClick={() => {
                                                    reset(3);
                                                    setSize(5);
                                                    setStep(4);
                                                }}>
                                                    <ButtonText text={'Less than 1cm Diameter'}/>
                                                </Button>
                                            </Col>
                                            <Col xs={6} style={{paddingLeft: 5, paddingRight: 5, height: 50}}>
                                                <Button variant={size === 6 ? "warning" : "outline-warning"}
                                                        style={{width: '100%'}} onClick={() => {
                                                    reset(3);
                                                    setSize(6);
                                                    setStep(4);
                                                }}>
                                                    <ButtonText text={'More than 1m Diameter'}/>
                                                </Button>
                                            </Col>
                                        </Row>

                                        : null}

                                    {camera === false && fov === true && (type >= 2 && type != null) ?

                                        <Row>
                                            <Col xs={4} style={{paddingLeft: 5, paddingRight: 5, height: 50}}>
                                                <Button variant={size === 7 ? "warning" : "outline-warning"}
                                                        style={{width: '100%'}} onClick={() => {
                                                    reset(3);
                                                    setSize(7);
                                                    setStep(4);
                                                }}>
                                                    <ButtonText text={'Less than 10cm'}/>
                                                </Button>
                                            </Col>
                                            <Col xs={4} style={{paddingLeft: 5, paddingRight: 5, height: 50}}>
                                                <Button variant={size === 8 ? "warning" : "outline-warning"}
                                                        style={{width: '100%'}} onClick={() => {
                                                    reset(3);
                                                    setSize(8);
                                                    setStep(4);
                                                }}>
                                                    <ButtonText text={'Between 10cm and 35cm'}/>
                                                </Button>
                                            </Col>
                                            <Col xs={4} style={{paddingLeft: 5, paddingRight: 5, height: 50}}>
                                                <Button variant={size === 9 ? "warning" : "outline-warning"}
                                                        style={{width: '100%'}} onClick={() => {
                                                    reset(3);
                                                    setSize(9);
                                                    setStep(4);
                                                }}>
                                                    <ButtonText text={'More than 35cm'}/>
                                                </Button>
                                            </Col>
                                        </Row>

                                        : null}

                                </section>

                                : null}

                            {[0, 2, 3, 5, 7, 8].indexOf(size) > -1 ?
                                <section style={{marginTop: 30}}>
                                    <Heading text={"5. Extra Chips?"}/>

                                    <div style={{paddingTop: 28}}/>


                                    <div style={{display: 'flex'}}>
                                        <div>
                                            <Button block style={{width: 50}} variant="warning"
                                                    onClick={() => setExtras(extras <= 0 ? 0 : extras - 1)}>-</Button>

                                        </div>

                                        <div>
                                            <Form.Control type="text" value={extras} style={{
                                                width: 100,
                                                height: '100%',
                                                display: 'inline',
                                                borderWidth: 0,
                                                textAlign: 'center'
                                            }}/>

                                        </div>

                                        <div>
                                            <Button style={{width: 50}} variant="warning"
                                                    onClick={() => setExtras(extras + 1)}>+</Button>

                                        </div>

                                    </div>
                                </section>

                                : null}

                        </Col>

                        <Col md={4} style={{display: 'flex', justifyContent: 'center'}}>

                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>


                                {step === 0 ?

                                    <img style={{padding: 50}}
                                         src={require('../../assets/images/windscreens/camera.png')}/>

                                    : null}
                                {step >= 1 ?

                                    <img style={{padding: 50}}
                                         src={require('../../assets/images/windscreens/fov.png')}/>

                                    : null}


                                {(step >= 4 || camera === true || size === 1 || size === 4 || size === 6 || size === 9 || size >= 10) ?

                                    <div>

                                        {price==="Quote Required"
                                            ?

                                            <div>

                                                <Card>
                                                <Card.Header style={{textAlign: 'center'}}>
                                                    <FontText size={20} weight={800}>Your Windscreen Chip Repair</FontText>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Card.Text>

                                                        <Table borderless style={{marginBottom:0}}>

                                                            {[{name: itemTitle, price: price}].map(item => (

                                                                    <tr>
                                                                        <td style={{paddingLeft: 0}}>{item['name']}</td>
                                                                        <td style={{
                                                                            paddingRight: 0,
                                                                            textAlign: 'right'
                                                                        }}>{item['price'] ? item['price'] : "TBD"}</td>
                                                                    </tr>
                                                            ))}


                                                        </Table>

                                                        <div>
                                                            Sorry, we can't give you a price for that repair without seeing the car. You can book in a time to take your car to the nearest Novus to have it assessed and fixed. You can then pay the agreed amount directly to the repairer.
                                                        </div>
                                                    </Card.Text>
                                                </Card.Body>
                                                </Card>
                                            </div>
                                        :<Basket job={job} items={[{name: itemTitle, price: price}]}/>
                                        }

                                        {/*<div*/}
                                        {/*    style={{*/}
                                        {/*        position:'absolute',*/}
                                        {/*        bottom:100,*/}
                                        {/*        backgroundColor:'#ffffff',*/}
                                        {/*        padding:20,*/}
                                        {/*        borderRadius:10,*/}
                                        {/*        borderWidth:1,*/}
                                        {/*        marginRight:15,*/}
                                        {/*        borderColor: 'lightgray',*/}
                                        {/*        // borderStyle: 'solid'*/}
                                        {/*    }}>*/}
                                        {/*    <div>*/}
                                        {/*        Sorry, we can't give you a price for that repair without seeing the car. You can book in a time to take your car to the nearest Novus to have it assessed and fixed. You can then pay the agreed amount directly to the repairer.*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <Button block style={{height: 70, marginTop: 20}} variant="warning" onClick={()=>{setOnGaragePage(true)}}>
                                            <FontText size={24} weight={800}>
                                                Continue
                                            </FontText>
                                        </Button>

                                    </div>


                                    : null}


                            </div>


                        </Col>

                    </Row>


                    {/*{(step >= 4 || camera === true || size === 1 || size === 4 || size === 6 || size === 9 || size >=10)?*/}
                    {/*    <div>*/}

                    {/*        {price==='Quote Required'&&(step >= 4 || camera === true || size === 1 || size === 4 || size === 6 || size === 9 || size >=10)?*/}
                    {/*            <div style={{marginTop:50, display:'flex', justifyContent:'center', textAlign:'center'}}>*/}
                    {/*                <FontText weight={200} size={20} style={{color:'black'}}>Sorry, we can't get you an exact price and will have to see the car.<br/>Press continue to book a time for an expert to have a look.</FontText>*/}
                    {/*            </div>*/}
                    {/*            : null}*/}


                    {/*        <Row style={{marginTop: 50}}>*/}


                    {/*            <Col md={{ span: 4, offset: 2 }}>*/}
                    {/*                <div style={{*/}
                    {/*                    backgroundColor:'#F2F2F2',*/}
                    {/*                    borderRadius:5,*/}
                    {/*                    minWidth:200,*/}
                    {/*                    display: 'flex',*/}
                    {/*                    justifyContent: 'center',*/}
                    {/*                    alignItems:'center',*/}
                    {/*                    padding:10*/}
                    {/*                }}>*/}

                    {/*                    <FontText size={36} weight={800}>*/}
                    {/*                        {price} {price.indexOf('$') > -1?<FontText size={24} weight={800}>incl GST</FontText>:null}*/}
                    {/*                    </FontText>*/}
                    {/*                </div>*/}
                    {/*            </Col>*/}
                    {/*            <Col md={4}>*/}
                    {/*                <Button block style={{height:'100%'}} variant="warning">*/}
                    {/*                    <FontText size={24} weight={800}>*/}
                    {/*                        Continue*/}
                    {/*                    </FontText>*/}
                    {/*                </Button>*/}

                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*    </div>*/}


                    {/*:null}*/}

                    <Row style={{marginTop: 100}}>
                        <Col md={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <img style={{width: '80%'}}
                                 src={require('../../assets/images/windscreens/new_novus_logo.png')}/>

                        </Col>
                        <Col md={8}>
                            <FontText size={20} weight={200}>
                                We've partnered with Novus to ensure your windscreen repair is completed perfectly every
                                time.
                                All repairs are fully covered for the entire time you own the vehicle, and are
                                guaranteed to
                                pass WoF/CoF inspections.
                                <br/>
                                <br/>
                                For details on the Australian/ New Zealand standard for windscreen repairs check here.
                            </FontText>
                        </Col>
                    </Row>

                </div>
            }
        </div>




    )

}
