import React, {useState, useEffect} from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {ButtonText, FontText, Heading, HeadingText} from '../Components/titles';



export default () => {

    return (

        <div>

            <div>
                <FontText size={30} weight={200}>404 Not Found.
                    <br/>Sorry, the page you are looking for could not be found.</FontText>

            </div>


            <Button style={{height:70, marginTop:20}} variant="warning" onClick={()=>{window.location.href = 'https://myautoshop.co.nz'}}>
                <FontText size={24} weight={800}>
                    Back to Home
                </FontText>
            </Button>
        </div>


    )

}
