export const Retail =  (identifier, setJob, setLoading, history) => {


    fetch("/book/request/" + identifier)
        .then(res => res.json())
        .then(
            (request) => {

                if (request.success)
                    setJob(request);
                else
                    history.push("/error/session");

                setLoading(false);


            })

};

export const Quote = (identifier, setJob, setServices, setLoading, history) => {

    fetch("/book/request/" + identifier)
        .then(res => res.json())
        .then(
            (request) => {

                if (request.success) {
                    setJob(request);

                    let services_array = [];

                    request.job.services.forEach((service) => {
                        services_array.push({
                            name: service,
                            price: 'TBD'
                        })
                    });

                    setServices(services_array);

                } else {
                    history.push("/error/session");

                }

                setLoading(false);


            })

}

