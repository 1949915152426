import React from 'react';



export const Heading = ({text})=>(
        <span
            style={{
                fontFamily: 'raleway,proxima-nova,Arial,Helvetica Neue,Helvetica,sans-serif',
                fontSize: 24,
                fontWeight: 800,
            }}
        >{text}</span>
    );


export const ButtonText = ({text})=>(
    <span
        style={{
            fontFamily: 'raleway,proxima-nova,Arial,Helvetica Neue,Helvetica,sans-serif',
            fontSize: 16,
            fontWeight: 600,
            color: 'black'
        }}
    >{text}</span>
);

export const FontText = ({children, size, weight, color, style, className, margin})=>(

    <div style={margin?{...style, marginBottom:margin}:style}>
        <span
            style={{
                fontFamily: 'proxima-nova,Arial,Helvetica Neue,Helvetica,sans-serif',
                fontSize: size?size:16,
                fontWeight: weight?weight:600,
                color: color?color:'black'
            }}
            className={className}
        >{children}</span>
    </div>

);
export const HeadingText = ({children, size, weight})=>(
    <span
        style={{
            fontFamily: 'raleway,proxima-nova,Arial,Helvetica Neue,Helvetica,sans-serif',
            fontSize: size?size:30,
            fontWeight: weight?weight:800,
            color: 'black'
        }}
    >{children}</span>
);
