import React, {useState, useEffect} from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {ButtonText, FontText, Heading, HeadingText} from '../../Components/titles';
import {BoolButtons} from '../../Components/form';

import {Quote as GetRequest} from "../../utils/GetRequest";


import Basket from "../retail/components/basket";


import {useParams, useHistory, useLocation} from "react-router-dom";
import Spinner from '../../Components/spinner'

const styles = {
    label: {marginBottom: 10, fontSize: 14, fontWeight: 600, color: '#191919', marginTop:10},
    input: {marginBottom: 10, fontSize: 14, fontWeight: 600, color: '#191919'}
};


export default () => {

    const params = useParams();

    const [job, setJob] = useState(null);
    const [services, setServices] = useState([]);

    const [firstname, setFirstname] =  useState('');
    const [lastname, setLastname] =  useState('');
    const [kms, setKms] =  useState('');
    const [registration, setRegistration] =  useState('');
    const [email, setEmail] =  useState('');
    const [phone, setPhone] =  useState('');
    const [additionals, setAdditionals] =  useState('');
    const [notes, setNotes] =  useState('');

    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [isAgreed, setAgreed] = useState(false);


    const [step, setStep] = useState(0);


    useEffect(()=>{

        GetRequest(params.identifier, setJob, setServices, setLoading, history);

        // fetch("/book/request/" + params.identifier)
        //     .then(res => res.json())
        //     .then(
        //         (request) => {
        //             setJob(request);
        //
        //             let services_array = [];
        //
        //             request.job.services.forEach((service)=>{
        //                 services_array.push({
        //                     name: service,
        //                     price: 'TBD'
        //                 })
        //             });
        //
        //             setServices(services_array);
        //         })

    }, [ params.identifier]);

    const location = useLocation();

    React.useEffect(() => {

        if (step === 1 && location.hash=== '') setStep(0);

    }, [location]);

    const general_questions = ["How soon do you want the work completed?", "Has a qualified mechanic or inspector diagnosed this work?", "Is your car under a manufacturer or 3rd party mechanical warranty?"];


    const onSubmit = (fn) => {

        let problem_combined = "";

        Object.keys(job.additionals).forEach(key=>{

            problem_combined += "<strong>" + key + "</strong><br/>";

            job.additionals[key].forEach(additional => {
                problem_combined += additional['question'] + " ";
                problem_combined += additionals["additionals_" + additional.id]?additionals["additionals_" + additional.id]:"N/A";
                problem_combined += "<br/>";
            });


        });

        problem_combined += "<strong>General Questions</strong><br/>";

        ['completed', 'qualified', 'warranty'].forEach((item, index)=>{
            problem_combined += general_questions[index] + " ";
            problem_combined += additionals["additionals_" + item]?additionals["additionals_" + item]:"N/A";
            problem_combined += "<br/>";
        });


        problem_combined += "<strong>Additional Notes:</strong> ";
        problem_combined += notes;

        fetch("/book/request/" + params.identifier + '/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                identifier: params.identifier,

                firstname: firstname,
                lastname: lastname,
                email: email,
                phone: phone,

                make: job.car.make,
                model: job.car.model,
                engine: job.car.engine,
                kms: kms,
                registration: registration,

                services: job.job.services,
                lat: job.job.lat,
                long: job.job.long,

                problem: problem_combined

            })
        })
            .then((res => res.json()))
            .then(data => {

                if (data.success){
                    history.push("/success");
                    window.scroll(0,0);
                } else {
                    alert("Error");
                }

            })

    };

    const onSelectChange = (event) => {

        setAdditionals({...additionals, [event.target.name]: event.target.value});

    };

    if (isLoading)
        return   <Spinner animation="border" variant="warning" />

    else return (

        <div>



        {job?
            <div>


                {step?
                    <HeadingText>
                        Add your contact details & you're done!
                    </HeadingText>
                :
                    <HeadingText>
                        Let us get you some quotes for your <span style={{color:'#fecb0a'}}>{job.car.make} {job.car.model}</span>
                    </HeadingText>
                }


            <Row style={{marginTop:20}}>
                <Col sm={8}>

                    {step === 1?

                        <div>
                    <Row className={'mb-sm-3'}>
                        <Col md={6}  className={'pr-md-1'}>
                            <Form.Label style={styles.label}>First Name <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control type="text" name="firstname" placeholder="First Name"
                                   value={firstname} onChange={(event)=>{setFirstname(event.target.value)}} style={{height:56}} />

                        </Col>
                        <Col md={6} className={'pl-md-1'}>
                            <Form.Label style={styles.label}>Last Name <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control type="text" name="lastname" placeholder="Last Name"
                                   value={lastname} onChange={(event)=>{setLastname(event.target.value)}} style={{height:56}}/>

                        </Col>
                    </Row>


                    <Row className={'mb-sm-3'}>
                        <Col md={6}  className={'pr-md-1'}>
                            <Form.Label style={styles.label}>Email <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control type="text" name="email" placeholder="Email"
                                          value={email} onChange={(event)=>{setEmail(event.target.value)}} style={{height:56}}/>

                        </Col>
                        <Col md={6} className={'pl-md-1'}>
                            <Form.Label style={styles.label}>Phone <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control type="text" name="phone" placeholder="Phone"
                                          value={phone} onChange={(event)=>{setPhone(event.target.value)}} style={{height:56}}/>

                        </Col>
                    </Row>

                    <Row className={'mb-sm-3'}>

                        <Col md={6}  className={'pr-md-1'}>
                            <Form.Label style={styles.label}>Vehicle Registration <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control type="text" name="registration" placeholder="eg. ABC123"
                                          value={registration} onChange={(event)=>{setRegistration(event.target.value)}} style={{height:56}}/>

                        </Col>
                        <Col md={6}  className={'pl-md-1'}>
                            <Form.Label style={styles.label}>Odometer (optional)</Form.Label>
                            <Form.Control type="text" name="kms" placeholder="Approximate KMs Travelled"
                                   value={kms} onChange={(event)=>{setKms(event.target.value)}} style={{height:56}}/>

                        </Col>
                    </Row>
                    <Row className={'mb-sm-3'}>

                        <Col md={12}  className={'pr-md-1'}>
                            <Form.Check style={{paddingTop: 15}}>
                                <Form.Check.Input isValid checked={isAgreed} onClick={()=>setAgreed(!isAgreed)}/>
                                <Form.Check.Label style={{color: 'black', fontSize:16}}>
                                    I agree to the <a style={{textDecoration: 'underline', color: '#5b5b5b'}} href={"https://myautoshop.co.nz/terms-and-conditions"} target="_blank" >Terms and Conditions</a> <span style={{color: 'red'}}>*</span>
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>

                            <Button style={{height:70, marginTop:28, marginBottom:28, width: 400, maxWidth:'100%'}} variant="warning" onClick={()=>{onSubmit(firstname)}}
                                    disabled={!(firstname && lastname && email && phone && registration && isAgreed)}


                            >
                                <FontText size={16} weight={800} color={'white'}>
                                    Get quote
                                </FontText>
                            </Button>

                        </div>:null}

                    {step === 0?

                        <div>

                            <FontText size={16} weight={200}>
                                We need to check in with our workshops to get prices for this job. Answer the below questions and you'll have quotes in your inbox within the next 24 hours.
                            </FontText>

                            <div style={{marginTop:39}}/>


                            {Object.keys(job.additionals).map((key)=> {

                                let category = job.additionals[key];

                                return category.map((additional, index)=>(

                                    <div>
                                        {index === 0?<FontText size={20} weight={800} color={'gray'} style={{marginBottom: 28}}>{`${key}`}</FontText>:null}

                                        <Row style={{marginBottom: 28}}>
                                            <Col md={7} style={{display: 'flex', alignItems: 'center'}}>
                                                <FontText size={16} weight={200} className={'addition-question'} margin={10}>{additional.question}</FontText>
                                            </Col>

                                            <Col md={5}>


                                                {additional.text ?
                                                    <Form.Control style={{height:56, fontSize: 16, fontFamily: 'proxima-nova'}} type="text" name={"additionals_" + additional.id} onChange={onSelectChange} value={additionals["additionals_" + additional.id]?additionals["additionals_" + additional.id]:''}/>

                                                    : null}
                                                {additional.select ?
                                                    <div>
                                                        <div className={'select-arrow'}></div>
                                                    <Form.Control style={{height:56, fontSize: 16, fontFamily: 'proxima-nova'}} as="select" name={"additionals_" + additional.id}
                                                                  onChange={onSelectChange}>
                                                        <option value="N/A" style={{fontSize:16}}>Select...</option>

                                                        {additional.options.map((answer) => (
                                                                <option style={{fontSize:16}} value={answer}>{answer}</option>

                                                            )
                                                        )}
                                                    </Form.Control>
                                                    </div>

                                                    : null}

                                            </Col>
                                        </Row>
                                    </div>


                                ))

                            })}

                            <div>
                                <FontText size={20} weight={800} color={'gray'} style={{marginBottom: 28}}>General Questions</FontText>

                                <Row style={{marginBottom: 28}}>
                                    <Col md={7} style={{display: 'flex', alignItems: 'center'}}>
                                        <FontText size={16} weight={200} className={'addition-question'} margin={10}>How soon do you want the work completed?</FontText>
                                    </Col>

                                    <Col md={5}>
                                        <div>
                                            <div className={'select-arrow'}></div>
                                            <Form.Control style={{height:56, fontSize: 16, fontFamily: 'proxima-nova'}} as="select" name={"additionals_completed"}
                                                          onChange={onSelectChange}>
                                                <option value="N/A" style={{fontSize:16}}>Select...</option>
                                                <option value="ASAP" style={{fontSize:16}}>ASAP</option>
                                                <option value="Next 1-2 weeks" style={{fontSize:16}}>Next 1-2 weeks</option>
                                                <option value="No rush, just price checking" style={{fontSize:16}}>No rush, just price checking</option>
                                            </Form.Control>
                                        </div>

                                    </Col>
                                </Row>
                                <Row style={{marginBottom: 28}}>
                                    <Col md={7} style={{display: 'flex', alignItems: 'center'}}>
                                        <FontText size={16} weight={200} className={'addition-question'} margin={10}>Has a qualified mechanic or inspector diagnosed this work?</FontText>
                                    </Col>

                                    <Col md={5}>
                                        <div>
                                            <div className={'select-arrow'}></div>
                                            <Form.Control style={{height:56, fontSize: 16, fontFamily: 'proxima-nova'}} as="select" name={"additionals_qualified"}
                                                          onChange={onSelectChange}>
                                                <option value="N/A" style={{fontSize:16}}>Select...</option>
                                                <option value="Yes" style={{fontSize:16}}>Yes</option>
                                                <option value="No" style={{fontSize:16}}>No</option>
                                            </Form.Control>
                                        </div>

                                    </Col>
                                </Row>
                                <Row style={{marginBottom: 28}}>
                                    <Col md={7} style={{display: 'flex', alignItems: 'center'}}>
                                        <FontText size={16} weight={200} className={'addition-question'} margin={10}>Is your car under a manufacturer or 3rd party mechanical warranty (ie. Protecta)? </FontText>
                                    </Col>

                                    <Col md={5}>
                                        <div>
                                            <div className={'select-arrow'}></div>
                                            <Form.Control style={{height:56, fontSize: 16, fontFamily: 'proxima-nova'}} as="select" name={"additionals_warranty"}
                                                          onChange={onSelectChange}>
                                                <option value="N/A" style={{fontSize:16}}>Select..</option>
                                                <option value="Yes" style={{fontSize:16}}>Yes</option>
                                                <option value="No" style={{fontSize:16}}>No</option>
                                            </Form.Control>
                                        </div>

                                    </Col>
                                </Row>
                            </div>


                            <Row>
                                    <Col sm={12}>
                                        <FontText size={20} weight={800} color={'gray'} style={{marginBottom: 28}}>Add a note for the mechanic</FontText>
                                        <Form.Control as="textarea" rows="8" placeholder={'Any additional information you can provide will improve the accuracy of the estimates you receive.'}
                                                      value={notes} onChange={(event)=>{setNotes(event.target.value)}} />
                                    </Col>
                                </Row>

                            <Button style={{height:70, marginTop:28, marginBottom:28, width: 400, maxWidth:'100%'}} variant="warning" onClick={()=>{setStep(1); window.scrollTo(0, 0); history.push("#details");}}
                            >
                                <FontText size={16} weight={800} color={'white'}>
                                    Continue
                                </FontText>
                            </Button>


                    </div>:null}


                </Col>

                <Col sm={4}>
                    <Basket job={job} quote={true} items={services} step={step}/>

                </Col>

            </Row>
            </div>






                :null}

        </div>


    )

}
