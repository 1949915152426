import React, {useState, useEffect} from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from '../../Components/spinner'
import {ButtonText, FontText, Heading, HeadingText} from '../../Components/titles';
import Tyre from './components/tyre'
import Price from './components/price'
import Basket from "./components/basket";
import Garages from "./garages";
import {Retail as GetRequest} from "../../utils/GetRequest";

import {useParams, useHistory} from "react-router-dom";

export default ({match}) => {

    const params = useParams();


    const [size, setSize] = useState({
        width: null,
        profile: null,
        rim: null,
    });
    const [prices, setPrices] = useState({
        budget: '-',
        standard: '-',
        premium: '-',
    });
    const [brands, setBrands] = useState({
        budget: null,
        standard: null,
        premium: null,
    });

    const [type, setType] = useState(null);
    const [quantity, setQuantity] = useState(2);
    const [total, setTotal] = useState('-');
    const [onGaragePage, setOnGaragePage] = useState(false);



    const onSelectChange = (event) => {

        setType(null);

        setSize({...size, [event.target.name]: event.target.value});

    };

    useEffect(()=>{
        getPrices();
    }, [size]);


    useEffect(()=>{

        setTotal(type?"$" + (parseFloat(prices[type].replace('$', '')) * quantity).toFixed(2): '-')

    }, [prices, quantity, type]);

    const history = useHistory();

    const [job, setJob] = useState({});
    const [isLoading, setLoading] = useState(true);

    useEffect(()=>{

        // GetRequest(params.identifier, setJob, setLoading, history);

        fetch("/book/tyres/" + params.identifier + "/size", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res => res.json()))
            .then(data => {

                if (data.success){
                    setSize({
                        width: data.width,
                        profile: data.profile,
                        rim: data.rim,
                    });
                }
                setLoading(false);


            })

    }, [params.identifier]);

    const getPrices = () => {
        if (!size.width || !size.profile || !size.rim) return;

        setPrices({
            budget: 'Loading',
            standard: 'Loading',
            premium: 'Loading',
        });

        fetch('/book/tyres', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(size)
        })
            .then((res => res.json()))
            .then(price_data => {
                setPrices({
                    budget: price_data.budget?`$${price_data.budget}`:'Unavailable',
                    standard: price_data.standard?`$${price_data.standard}`:'Unavailable',
                    premium: price_data.premium?`$${price_data.premium}`:'Unavailable',
                });
                setBrands({
                    budget: price_data.budget_brand?price_data.budget_brand:null,
                    standard: price_data.standard_brand?price_data.standard_brand:null,
                    premium: price_data.premium_brand?price_data.premium_brand:null,
                });

                if (price_data.standard) return setType('standard');
                if (price_data.budget) return setType('budget');
                if (price_data.premium) return setType('premium');
            })
    };


    const images = {

        "Sunwide": {
            tyre: require('../../assets/images/tyres/sunwide.jpg'),
            logo: require('../../assets/images/tyres/sunwide-logo.jpg')
        },
        "Double Coin": {
            tyre: require('../../assets/images/tyres/doublecoin.jpg'),
            logo: require('../../assets/images/tyres/doublecoin-logo.png')
        },
        "GT Radial": {
            tyre: require('../../assets/images/tyres/gtradial.jpg'),
            logo: require('../../assets/images/tyres/gtradial-logo.png')
        },
        "Giti": {
            tyre: require('../../assets/images/tyres/giti.png'),
            logo: require('../../assets/images/tyres/giti-logo.jpg')
        },
        "Falken": {
            tyre: require('../../assets/images/tyres/falken.png'),
            logo: require('../../assets/images/tyres/falken-logo.png')
        },


    };

    if (isLoading)
        return   <Spinner animation="border" variant="warning" />

    else return (

        <div>

        {onGaragePage?

        <Garages
            type={'tyres'}

            onGarageSelect={(id, data)=>{

            setLoading(true);

            fetch("/book/tyres/" + params.identifier + '/book', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    identifier: params.identifier,
                    garage: id,
                    type: type,
                    quantity: quantity,
                    size: size,

                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                    phone: data.phone,
                    kms: data.kms,
                    registration: data.registration,



                })
            })
                .then((res => res.json()))
                .then(data => {

                    if (data.success){
                        window.location = data.url;
                    } else {
                        history.push('/error/session')
                    }

                })

        }}/>

:

<Row>
    <Col lg={8}>

        <HeadingText>
            Lets get your new <span style={{color:'#fecb0a'}}>Tyres</span> sorted!
        </HeadingText>

        <div style={{marginTop:20}}>

            <FontText size={16} weight={200}>
                We've worked out the best fitting tyre for your car. All you need to do is decide how much you want to spend, and how many tyres you need.
            </FontText>

            <section style={{paddingBottom:50, marginTop:50}}>



                <Heading text={"1. Confirm your tyre size."}/> <FontText size={14} weight={200}>(Adjust for aftermarket wheels)</FontText>


                <Row style={{paddingTop:28}}>
                    <Col sm={4} style={{marginBottom:20}}>
                        <FontText>Width</FontText>
                        <div className={'select-arrow-tyres'}></div>

                        <Form.Control as="select" name={'width'} value={size.width} onChange={onSelectChange} style={{height:50}}>
                            <option>Select...</option>
                            <option value={145}>145</option>
                            <option value={155}>155</option>
                            <option value={165}>165</option>
                            <option value={175}>175</option>
                            <option value={185}>185</option>
                            <option value={195}>195</option>
                            <option value={205}>205</option>
                            <option value={215}>215</option>
                            <option value={225}>225</option>
                            <option value={235}>235</option>
                            <option value={245}>245</option>
                            <option value={255}>255</option>
                            <option value={265}>265</option>
                            <option value={275}>275</option>
                            <option value={285}>285</option>
                            <option value={295}>295</option>
                            <option value={305}>305</option>
                            <option value={315}>315</option>
                            <option value={325}>325</option>
                            <option value={27}>27</option>
                            <option value={30}>30</option>
                            <option value={31}>31</option>
                            <option value={32}>32</option>
                            <option value={33}>33</option>
                            <option value={35}>35</option>
                            <option value={37}>37</option>
                        </Form.Control>
                    </Col>
                    <Col sm={4} style={{marginBottom:20}}>
                        <FontText>Profile</FontText>
                        <div className={'select-arrow-tyres'}></div>

                        <Form.Control as="select" name={'profile'} value={size.profile} onChange={onSelectChange} style={{height:50}}>
                            <option>Select...</option>
                            <option value={25}>25</option>
                            <option value={30}>30</option>
                            <option value={35}>35</option>
                            <option value={40}>40</option>
                            <option value={45}>45</option>
                            <option value={50}>50</option>
                            <option value={55}>55</option>
                            <option value={60}>60</option>
                            <option value={65}>65</option>
                            <option value={70}>70</option>
                            <option value={75}>75</option>
                            <option value={80}>80</option>
                            <option value={85}>85</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                        </Form.Control>
                    </Col>
                    <Col sm={4}>
                        <FontText>Rim</FontText>
                        <div className={'select-arrow-tyres'}></div>

                        <Form.Control as="select" name={'rim'} value={size.rim} onChange={onSelectChange} style={{height:50}}>
                            <option>Select...</option>
                            <option value={10}>10</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                            <option value={16}>16</option>
                            <option value={17}>17</option>
                            <option value={18}>18</option>
                            <option value={19}>19</option>
                            <option value={20}>20</option>
                            <option value={21}>21</option>
                            <option value={22}>22</option>
                            <option value={24}>24</option>
                        </Form.Control>
                    </Col>
                </Row>
            </section>

            <section style={{paddingBottom:50}}>


                <Heading text={'2. How many tyres do you need?'}/>

                <Row style={{paddingTop:28}}>
                    <Col xs={3} style={{paddingRight:5}}>
                        <Button variant={quantity===1?"warning":"outline-warning"} style={{width:'100%', height:50}} onClick={()=>setQuantity(1)}>
                            <ButtonText text={'1'}/>
                        </Button>
                    </Col>
                    <Col xs={3} style={{paddingLeft:5, paddingRight:5}}>
                        <Button variant={quantity===2?"warning":"outline-warning"} style={{width:'100%', height:50}} onClick={()=>setQuantity(2)}>
                            <ButtonText text={'2'}/>
                        </Button>
                    </Col>
                    <Col xs={3} style={{paddingLeft:5, paddingRight:5}}>
                        <Button variant={quantity===3?"warning":"outline-warning"} style={{width:'100%', height:50}} onClick={()=>setQuantity(3)}>
                            <ButtonText text={'3'}/>
                        </Button>
                    </Col>
                    <Col xs={3} style={{paddingLeft:5}}>
                        <Button variant={quantity===4?"warning":"outline-warning"} style={{width:'100%', height:50}} onClick={()=>setQuantity(4)}>
                            <ButtonText text={'4'}/>
                        </Button>
                    </Col>
                </Row>
            </section>

            <section style={{paddingBottom:50}}>
                <Heading text={'3. How much do you want to spend?'}/>

                <Row style={{paddingTop:28}}>

                    <Col sm={4} style={{paddingLeft:5, paddingRight:5}}>
                        <Tyre
                            title={"Budget"}
                            image={images[brands['budget']?brands['budget']:"Double Coin"].tyre}
                            brand={images[brands['budget']?brands['budget']:"Double Coin"].logo}
                            price={prices.budget}
                            isSelected={type === "budget"}
                            onSelect={()=>setType('budget')}
                            size={size}
                            priceColor={type==='budget'?'#FECB0A':'#d2d2d2'}
                        />
                    </Col>
                    <Col sm={4} style={{paddingLeft:5, paddingRight:5}}>
                        <Tyre
                            title={"Standard"}
                            image={images[brands['standard']?brands['standard']:"GT Radial"].tyre}
                            brand={images[brands['standard']?brands['standard']:"GT Radial"].logo}
                            price={prices.standard}
                            isSelected={type === "standard"}
                            onSelect={()=>setType('standard')}
                            size={size}
                            priceColor={type==='standard'?'#FECB0A':'#d2d2d2'}




                        />
                    </Col>
                    <Col sm={4} style={{paddingLeft:5, paddingRight:5}}>
                        <Tyre
                            title={"Premium"}
                            image={images[brands['premium']?brands['premium']:"Falken"].tyre}
                            brand={images[brands['premium']?brands['premium']:"Falken"].logo}
                            price={prices.premium}
                            isSelected={type === "premium"}
                            onSelect={()=>setType('premium')}
                            size={size}
                            priceColor={type==='premium'?'#FECB0A':'#d2d2d2'}



                        />
                    </Col>
                </Row>
            </section>






        </div>
    </Col>
    <Col lg={4} style={{display: 'flex', flexDirection: 'column'}}>
            <img src={require('../../assets/images/tyrehelp.png')}/>


            <div style={{marginTop:50}}>
                <Basket job={job} tyres={true} items={[{name: 'Tyres', price:type?total:'TBD'}, {name: 'Expert fitting balancing + valve & tyre disposal ($32.91 per tyre)', price:'$' + (32.91*quantity).toFixed(2)}]}/>

                <Button disabled={type===null} block style={{height:70, marginTop:20}} variant="warning" onClick={()=>{setOnGaragePage(true)}}>
                    <FontText size={16} weight={800} color={'#ffffff'}>
                        Continue
                    </FontText>
                </Button>

            </div>


        {/*<Heading text={"Your Price"}/>*/}
        {/*<br/>*/}
        {/*<Heading text={"Per Tyre"}/>*/}
        {/*<Price price={type?prices[type]:'-'}/>*/}

        {/*<br/>*/}


        {/*<Heading text={"Total"}/>*/}
        {/*<Price price={total}/>*/}

        {/*<br/>*/}

        {/*<Button size={'lg'} style={{width: 200}} variant="warning"><ButtonText text={'Continue'}/></Button>*/}




    </Col>
</Row>


        }

        </div>


    )

}
