import Spinner from "react-bootstrap/Spinner";
import React from "react";

export default ()=>(

    <div style={{display: 'flex', justifyContent:'center'}}>
        <Spinner animation="border" variant="warning" />
    </div>

)
