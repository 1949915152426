import React from 'react';

export default () => (

    <footer data-v-77c53c4d="" className="site-footer">
        <div data-v-77c53c4d="">
            <div data-v-77c53c4d="" className="contact">
                <a data-v-77c53c4d="" href="mailto:hello@myautoshop.co.nz" className="email">hello@myautoshop.co.nz</a>
                <a data-v-77c53c4d="" href="tel:09 887 8317" className="phone">(09) 887 8317</a>
            </div>
        </div>
        <div data-v-77c53c4d="" className="flex flex-between">
            <div data-v-77c53c4d="" className="left">
                <div data-v-77c53c4d="" className="social">
                    <a data-v-77c53c4d="" href="https://twitter.com" target="_blank">
                        <span data-v-77c53c4d="" className="icon icon-twitter"></span>
                    </a>
                    <a data-v-77c53c4d="" href="https://www.facebook.com/myautoshop.co.nz/" target="_blank">
                        <span data-v-77c53c4d="" className="icon icon-facebook"></span>
                    </a>
                    <a data-v-77c53c4d="" href="https://www.instagram.com/myautoshopnzl/" target="_blank">
                        <span data-v-77c53c4d="" className="icon icon-instagram"></span>
                    </a>
                    <a data-v-77c53c4d="" href="https://www.linkedin.com/company/64555158" target="_blank">
                        <span data-v-77c53c4d="" className="icon icon-linkedin2"></span>
                    </a>
                </div>
                <div data-v-77c53c4d="" className="legal">
                    <p data-v-77c53c4d="">
                        2020 © Myautoshop &nbsp; | &nbsp;
                        <a data-v-77c53c4d="" href="https://myautoshop.co.nz/terms-and-conditions"><u
                            data-v-77c53c4d="">Legal</u></a>
                        <br data-v-77c53c4d="" className="mobile" />
                            <a data-v-77c53c4d="" href="http://www.brownpaperbag.co.nz/" target="_blank"
                               className="bpb">web design by brownpaperbag</a>
                    </p>
                </div>
            </div>
            <div data-v-77c53c4d="" className="right">
                <a data-v-77c53c4d="" href="https://nz.trustpilot.com/review/myautoshop.co.nz" target="_blank">
                    <img data-v-77c53c4d="" src={require('../assets/images/kudos-trustpilot.jpg')} alt="My Autoshop: trustpilot" /></a>
                <a data-v-77c53c4d="" href="https://www.mta.org.nz/" target="_blank">
                    <img data-v-77c53c4d="" src={require('../assets/images/kudos-mta.jpg')} alt="My Autoshop: mta" />
                </a>
            </div>
        </div>
    </footer>
)
