import React from 'react';
import {FontText} from "../../../Components/titles";

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default ({items, tyres, job, quote, step})=> {


    let total = 0;

    items.forEach((b)=>{
        total += isNaN(parseFloat(b['price'].replace("$", ""))) ? 0 : parseFloat(b['price'].replace("$", ""))
    });

    total = total.toFixed(2);

    let afterpay = (total/4).toFixed(2);

    // let total = items.reduce((a, b) => {
    //     return a + isNaN(parseFloat(b['price'].replace("$", ""))) ? 0 : parseFloat(b['price'].replace("$", ""))}, 0);

    return (

    <div>
        <Card>
            <Card.Header style={{textAlign: 'center'}}>
                <FontText size={20} weight={800}>{quote?'Quotes to be requested':'Your Cart'}</FontText>
            </Card.Header>
            <Card.Body>
                <Card.Text>

                    <Table borderless style={{marginBottom:0}}>

                        {items.map(item => (

                            quote?

                                    <tr>
                                        <td style={{paddingLeft: 0, paddingRight: 0}}>
                                            <img src={require('../../../assets/images/basket/tick.png')} alt="Check" style={{width:25}}/>
                                        </td>
                                        <td style={{
                                            paddingRight: 0,
                                            paddingLeft: 5,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: 16,
                                        }}>{item['name']}</td>
                                    </tr>

                                    :
                            <tr>
                                <td style={{paddingLeft: 0}}>{item['name']}</td>
                                <td style={{
                                    paddingRight: 0,
                                    textAlign: 'right'
                                }}>{item['price'] ? item['price'] : "TBD"}</td>
                            </tr>
                        ))}


                    </Table>

                    {/*<hr/>*/}

                    {total === 0?


                        tyres?
                                <FontText weight={200}>
                                    <div style={{textAlign:'center'}}>
                                        Please select your tyres before continuing.
                                    </div>

                                </FontText>



                                :

                            quote?null



                                :
                                <FontText weight={200}>
                                    <div style={{textAlign:'center'}}>
                                        Sorry, we can't get you an exact price and will have to see the car.<br/><br/>Press continue to book
                                        a time for an expert to have a look.
                                    </div>

                                </FontText>



                        :
                        !quote?
                        <div>
                    <Row>

                        <Col sm={6}>
                            <FontText size={16} weight={200}>
                                Total
                            </FontText>
                            <FontText size={12} weight={200}>
                                Incl 15% GST
                            </FontText>
                        </Col>
                        <Col sm={6} style={{display: "flex", alignItems: 'center', justifyContent: 'flex-end'}}>
                            <FontText size={36}>
                                ${total}
                            </FontText>
                        </Col>
                    </Row>
                            <br/>
                    <FontText size={12} weight={200}>
                        Or 4 easy payments of ${afterpay} with
                    </FontText>
                    <img style={{width: 100}} src={require('./../../../assets/images/Afterpay_Logo_Black.png')}/>
                    <a href="https://www.afterpay.com/en-NZ/terms-of-service" target={'_blank'} style={{
                        marginLeft: 5,
                        color: "gray",
                        textDecoration: 'underline'
                    }}>More info</a>

                        </div>:null}

                </Card.Text>
            </Card.Body>
        </Card>
        {quote?


        <Card style={{marginTop:20}}>
            <Card.Header style={{textAlign: 'center'}}>
                <FontText size={20} weight={800}>{step?"Why book with My Auto Shop?":"What happens next?"}</FontText>
            </Card.Header>
            <Card.Body>
                <Card.Text>


                    {step ?
                        <div>
                            <FontText size={24} weight={800}>
                                <div style={{textAlign: 'center'}}>

                                    <Row style={{marginTop: 10}}>

                                        <Col xs={4} style={{display: 'flex', alignItems: 'center'}}>
                                            <img src={require('../../../assets/images/basket/icon_4.png')}
                                                 style={{width: 120}}/>
                                        </Col>

                                        <Col xs={8} style={{display: 'flex', alignItems: 'center'}}>
                                            <div>
                                                <FontText size={18} weight={800} style={{textAlign: 'left'}}>
                                                    Trusted Repairers
                                                </FontText>
                                                <FontText size={14} weight={200}
                                                          style={{marginTop: 5, textAlign: 'left'}}>
                                                    MTA approved workshops with clear ratings and reviews.
                                                </FontText>
                                            </div>


                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 18}}>

                                        <Col xs={4} style={{display: 'flex', alignItems: 'center'}}>
                                            <img src={require('../../../assets/images/basket/icon_5.png')}
                                                 style={{width: 120}}/>
                                        </Col>

                                        <Col xs={8} style={{display: 'flex', alignItems: 'center'}}>
                                            <div>
                                                <FontText size={18} weight={800} style={{textAlign: 'left'}}>
                                                    Never Overpay
                                                </FontText>
                                                <FontText size={14} weight={200}
                                                          style={{marginTop: 5, textAlign: 'left'}}>
                                                    See the price for your car in advance, guaranteed.
                                                </FontText>
                                            </div>


                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 18}}>

                                        <Col xs={4} style={{display: 'flex', alignItems: 'center'}}>
                                            <img src={require('../../../assets/images/basket/icon_6.png')}
                                                 style={{width: 120}}/>
                                        </Col>

                                        <Col xs={8} style={{display: 'flex', alignItems: 'center'}}>
                                            <div>
                                                <FontText size={18} weight={800} style={{textAlign: 'left'}}>
                                                    We Make It EASY
                                                </FontText>
                                                <FontText size={14} weight={200}
                                                          style={{marginTop: 5, textAlign: 'left'}}>
                                                    Book online 24/7 and choose home pick up and drop off.
                                                </FontText>
                                            </div>


                                        </Col>
                                    </Row>
                                </div>

                            </FontText>

                        </div>

                        :


                        <div>
                            <FontText size={24} weight={800}>
                                <div style={{textAlign: 'center'}}>

                                    <div style={{marginTop: 18}}>
                                        <img src={require('../../../assets/images/basket/icon_1.png')}
                                             style={{width: 120}}/>

                                        <FontText size={16} weight={200} style={{marginTop: 18}}>
                                            We gather quotes from local garages.
                                        </FontText>
                                    </div>

                                    <div style={{marginTop: 18}}>
                                        <img src={require('../../../assets/images/basket/icon_2.png')}
                                             style={{width: 120}}/>

                                        <FontText size={16} weight={200} style={{marginTop: 18}}>
                                            Then we email you the best ones within 24 hours.
                                        </FontText>
                                    </div>
                                    <div style={{marginTop: 18}}>
                                        <img src={require('../../../assets/images/basket/icon_3.png')}
                                             style={{width: 120}}/>

                                        <FontText size={16} weight={200} style={{marginTop: 18}}>
                                            You'll be back on the road in no time.
                                        </FontText>
                                    </div>

                                </div>

                            </FontText>

                        </div>

                    }

                </Card.Text>
            </Card.Body>
        </Card>
            :null}
    </div>
    )

}
